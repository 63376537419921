.pagefreegluteworkout {
  width: 100%;
  min-height:100vh;
  text-align: center;
}

.textinput {
  width: 85%;
  font-size: 20pt;
  text-align: center;
  line-height: 30pt;
  border-radius: 10pt;
  /*box-shadow:0 4pt 6pt rgb(179, 186, 252);*/
  box-shadow:0 4pt 6pt rgb(253, 232, 255);
  /*border: solid 2pt #ff00b3; */
}
.selectinput {
  width: 85%;
  font-size: 20pt;
  text-align: center;
  line-height: 30pt;
  height: 35pt;
  border-radius: 10pt;
  box-shadow:0 4pt 6pt rgb(253, 232, 255);
  background-color: white;
}

.selectinput option {
  background-color: white;
}

.selectinput.placeholder {
  color: #707070;
}

.selectinput option.placeholder {
  color: #707070;
}

.goalInput {
  width: 20pt;
  height: 20pt;
  box-shadow:0 4pt 6pt rgb(179, 186, 252);
  margin: 0 10pt 10pt 0;
}


.question {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20pt;

  color: rgb(255, 184, 234);
  font-weight: bold;
  font-style: italic;
  padding-bottom: 15pt;
}

.goalsGroup {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20pt;

  color: white;
  font-weight: bold;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  width:fit-content;
}

.goalsGroup.error {
  color: #ff4141;
  animation: shake 0.5s 1 linear;
}

.selectinput.error, .textinput.error, .checkboxinput.error {
  border-color: #FF0000;
  background-color: #ffd0d0;
  animation: shake 0.5s 1 linear;
}

.checkboxinput {
  accent-color: #c6408a;
}

.errormessage {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14pt;
  color: white;
  background-color: red;
  display: inline-block;
  padding: 0 5pt 0 5pt;
  border: solid 1pt red;
  border-radius: 8pt;
  font-weight: bold;
  margin: 2pt;
}

.firstnameinput, .lastnameinput, .genderinput, .ageinput, .priortraininginput {
  margin-bottom: 12pt;
}

.nextbutton {
  margin-top: 20pt;
}

.logotop {
  width: 100%;
}

.vertcenter {
  width: 100%;
}

.offertitle {
  margin-bottom: 10pt;
}

.offertitleleft {
  width:35vw;
  margin-right: -5vw;
}

.offertitlepeach {
  width:33vw;
  margin-top: -10pt;
}

.offertitleright {
  width:35vw;
  margin-left: -5vw;
}

.consultyesbutton {
  width: 200pt;
  margin: 10pt 0 20pt 0;
}

.consultnobutton {
  width: 200pt;
  
}
html {
  background:  url(../../public/images/comingsoon/background.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.main {
  text-align: center;
}

.sirenneon, .steelneon {
  width: 20vw;
  vertical-align: middle;
}

.logo {
  vertical-align: middle;
}

.copyright {
  position: absolute;
  font-family: arial;
  color: white;
  font-size: 10pt;
  text-align: center;
  bottom: 0;
  margin-left: auto;
  margin-right:auto;
  width: 99vw;
}

.vertcenter {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
}

.steelneon.bottom {
  display: none;
}




@media only screen and (max-width: 768px) {
  .sirenneon, .steelneon {
    width: 65vw;
  }

  .steelneon {
    margin-bottom: -20pt;
  }
/*
  .steelneon.bottom {
    display: inline;
    margin-top: -50pt;
    margin-bottom: 0;
  }
*/
  .sirenneon {
    margin-top: -40pt;
  }

  .logo {
    height: 70vh;
  }
}


.flicker {
  animation:flicker 10s infinite ease-in-out;
}

.float {
  animation:float 10s infinite ease-in-out;
}

@keyframes flicker {
  0%{filter: brightness(1);}
  1%{filter: brightness(.9);}
  2%{filter: brightness(1);}
  22%{filter: brightness(1);}
  23%{filter: brightness(.9);}
  24%{filter: brightness(1);}
  40%{filter: brightness(1.1);}
  60%{filter: brightness(1);}
  61%{filter: brightness(.9);}
  62%{filter: brightness(1);}
  70%{filter: brightness(1);}
  71%{filter: brightness(.9);}
  76%{filter: brightness(1);}
  98%{filter: brightness(1);}
  99%{filter: brightness(1);}
  100%{filter: brightness(1);}
}

@keyframes float {
  0%{margin-bottom: 0em}
  25%{margin-bottom:-0.9em}
  50%{margin-bottom:0em}
  75%{margin-bottom:-0.9em}
  100%{margin-bottom:0em;}
}

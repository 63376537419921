body {
  padding: 0;
  margin: 0;

}
.bggrad {
  /* background-image: linear-gradient(#5f6aa1, #2b314f); */
  background-image: linear-gradient(#555555, #000000);
}
.pagehome {
  width: 100%;
  height:100vh;
}

.vertcenter {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.bouncy{
  animation:bouncy 5s infinite linear;
  position:relative;
}

.pulsate {
  animation:pulsate 5s infinite linear;
}

.pulsate2 {
  animation:pulsate2 5s infinite linear;
}

@keyframes pulsate {
  0%{filter: brightness(1);}
  20%{filter: brightness(1);}
  40%{filter: brightness(2);}
  60%{filter: brightness(1);}
  100%{filter: brightness(1);}
}

@keyframes pulsate2 {
  0%{filter: brightness(1);}
  60%{filter: brightness(1);}
  80%{filter: brightness(2);}
  100%{filter: brightness(1);}
}


@keyframes bouncy {
  0%{top:0em}
  40%{top:0em}
  43%{top:-0.9em}
  46%{top:0em}
  48%{top:-0.4em}
  50%{top:0em}
  100%{top:0em;}
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

  .button3{
    display:inline-block;
    padding:0.3em 1.2em;
    margin:0 0.3em 0.3em 0;
    border: solid 2pt white;
    border-radius:2em;
    box-sizing: border-box;
    text-decoration:none;
    font-size: 20pt;
    font-family:'Roboto',sans-serif;
    font-weight:300;
    color:#FFFFFF;

    background-image: linear-gradient(#57aee0,#57aee0,#266488);
    text-align:center;
    transition: all 0.2s;
    }
    .button3:hover{
    background-color:#4095c6;
    }

    .buttonpink{
      display:inline-block;
      padding:0.3em 1.2em;
      /*margin:0 0.3em 0.3em 0;*/
      border: solid 2pt white;
      border-radius:2em;
      box-sizing: border-box;
      text-decoration:none;
      font-size: 20pt;
      font-family:'Roboto',sans-serif;
      font-weight:bold;
      color:#FFFFFF;
  
      background-image: linear-gradient(#e057d4,#e057ab,#882662);
      text-align:center;
      transition: all 0.2s;
    }
    .buttonpink:hover{
      background-color:#c6408a;
    }
    .buttongray{
      display:inline-block;
      padding:0.3em 1.2em;
      /*margin:0 0.3em 0.3em 0;*/
      border: solid 2pt white;
      border-radius:2em;
      box-sizing: border-box;
      text-decoration:none;
      font-size: 20pt;
      font-family:'Roboto',sans-serif;
      font-weight:bold;
      color:#FFFFFF;
  
      background-image: linear-gradient(#c0bebe,#a4acb9,#494949);
      text-align:center;
      transition: all 0.2s;
    }
    .buttongray:hover{
      background-color:#959dac;
    }


   